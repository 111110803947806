import React, { Fragment } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Grid, Typography } from "@material-ui/core";

function App() {
  return (
    <Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: "98vh", overflow: "hidden" }}
        direction="column"
      >
        <Grid item style={{ textAlign: "center" }}>
          <Typography
            variant="h3"
            style={{ color: "#273F57" }}
            className="font"
          >
            Nathan Dimmer
          </Typography>
          <Typography
            variant="h5"
            style={{ color: "#273F57" }}
            className="font"
          >
            Web Developer
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "2rem" }}>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ height: "45px" }}
            alignItems="center"
          >
            <Grid item>
              <a
                href="https://www.linkedin.com/in/nathandimmer/"
                target="_blank"
              >
                <img
                  src="../../Assets/linkedin.svg"
                  style={{ width: "45px" }}
                  alt="LinkedIn Icon"
                ></img>
              </a>
            </Grid>
            <Grid item>
              <a
                href="https://drive.google.com/file/d/1rmTJeLP4k7rjiGb4AxxfHDDk4HL-Y0Cy/view"
                target="_blank"
              >
                <img
                  src="../../Assets/noun_Resume.svg"
                  style={{ height: "45px" }}
                  alt="Resume Icon"
                ></img>
              </a>
            </Grid>
            <Grid item>
              <a href="https://devpost.com/NathanDimmer" target="_blank">
                <img
                  src="../../Assets/dev-post.svg"
                  style={{ height: "45px" }}
                  alt="Devpost Icon"
                ></img>
              </a>
            </Grid>
            <Grid item>
              <a href="https://github.com/nathandimmer" target="_blank">
                <img
                  src="../../Assets/GitHub.svg"
                  style={{ width: "45px" }}
                  alt="Github Icon"
                ></img>
              </a>
            </Grid>
            <Grid item>
              <a href="mailto:nathandimmer@gmail.com">
                <img
                  src="../../Assets/noun_Email.svg"
                  style={{ width: "45px" }}
                  alt="Email Icon"
                ></img>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default App;
